import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useGlobals } from './GlobalsProvider'
import * as firestoreAPI from './firestoreAPI'
import { convertTimestampToDate } from '../mgt/timeMgt'
import { customInactiveTxt, inactiveTxt } from '../mgt/constants'

const LogsContext = createContext()

export const useLogs = () => {
    return useContext(LogsContext)
}

export const LogsProvider = ({ children }) => {
    const {
        user, selectedHostId,
        selectedDate, setSelectedDate
    } = useGlobals()
    const [logs, setLogs] = useState([])
    const [filteredLogs, setFilteredLogs] = useState([])
    const [currentSort, setCurrentSort] = useState({ column: 1, desc: true })
    const selectedLines = useRef([])
    const [logsRetrieved, setLogsRetrieved] = useState(false)
    const [refreshingLogs, setRefreshingLogs] = useState(false)

    useEffect(() => {
        getLogs()
    }, [selectedHostId, user?.uid])


    async function getLogs() {
        const success = await handleGetLogs()
        setLogsRetrieved(success)
    }

    async function handleGetLogs() {
        if (refreshingLogs || !selectedHostId || !user?.uid) return false

        setRefreshingLogs(true)
        const logs = await firestoreAPI.getUserComputerLogs({
            userId: user?.uid,
            hostId: selectedHostId,
        })

        const cleanLogs = cleanupLogs(logs)

        setLogs(cleanLogs)
        refreshLogs({ newDate: selectedDate, initialLogs: cleanLogs })

        setRefreshingLogs(false)
        selectedLines.current = []

        return true
    }

    function cleanupLogs(logs) {
        return logs
            .map(log => {
                log.logs = log.logs
                    .sort((a, b) => b.timestamp.seconds - a.timestamp.seconds)
                    .map(l => {
                        if (l.title === inactiveTxt)
                            l.title = customInactiveTxt
                        return l
                    })
                if (log.title === inactiveTxt)
                    log.title = customInactiveTxt
                return log
            })
    }

    function handleDateChange(days) {
        selectedLines.current = []
        const newDate = typeof days === 'string' ? selectSpecificDate(days) : changeDateBy(days)
        setSelectedDate(newDate)
        refreshLogs({ newDate })
    }

    function selectSpecificDate(days) {
        const dateParts = days.split('/')
        const newDate = new Date(dateParts[2], dateParts[1] - 1, dateParts[0])
        return newDate
    }

    function changeDateBy(days) {
        const newDate = days === 0 ? new Date() : new Date(selectedDate)
        newDate.setDate(newDate.getDate() + days)
        return newDate
    }

    const refreshLogs = useCallback(async ({ newDate, initialLogs }) => {
        const lLogs = initialLogs || [...logs]
        const filteredLogs = lLogs.filter(log => {
            const logDateTime = convertTimestampToDate(log.startTime)
            const logDate00 = new Date(logDateTime.setHours(0, 0, 0, 0))
            const selectedDate00 = new Date(newDate.setHours(0, 0, 0, 0))
            const logDateStr = logDate00.toLocaleDateString('nl-BE')
            const selectedDateStr = selectedDate00.toLocaleDateString('nl-BE')

            return logDateStr === selectedDateStr
        })
        setFilteredLogs(filteredLogs)
        setTimeout(() => updateSorting(), 0)
    }, [logs])

    const updateSorting = useCallback((newSort) => {
        setFilteredLogs(prev => {
            const newLogs = [...prev]

            if (!newSort) {
                newSort = currentSort
            }

            newLogs.sort((a, b) => {
                switch (newSort.column) {
                    case 0:
                        return newSort.desc ? b.startTime.seconds - a.startTime.seconds : a.startTime.seconds - b.startTime.seconds
                    case 1:
                        return newSort.desc ? b.startTime.seconds - a.startTime.seconds : a.startTime.seconds - b.startTime.seconds
                    case 2:
                        return newSort.desc ? b.title.localeCompare(a.title) : a.title.localeCompare(b.title)
                    default:
                        return 0
                }
            })

            return newLogs
        })
    }, [currentSort])

    const exports = {
        logs, setLogs, getLogs,
        filteredLogs, setFilteredLogs,
        refreshingLogs, logsRetrieved,
        currentSort, setCurrentSort, updateSorting,
        handleDateChange
    }

    return (
        <LogsContext.Provider value={exports}>
            {children}
        </LogsContext.Provider>
    )
}
