import React, { useEffect, useState } from 'react'
import VerticalSpacer from './VerticalSpacer.jsx'
import { format } from 'date-fns'
import { PauseIcon, PlayIcon, RefreshIcon, RightArrowIcon, RightArrowsIcon } from './Icons.jsx'
import { convertTimestampToDate } from '../mgt/timeMgt.js'
import ModularButton from './ModularButton.jsx'
import { remToPixels } from '../mgt/layoutMgt.js'
import { customInactiveTxt } from '../mgt/constants.js'
import { formatTimeSpent } from '../mgt/jiraAPI.js'
import { useLogs } from '../mgt/LogsProvider.js'
import { useGlobals } from '../mgt/GlobalsProvider.js'

const WeekSelector = () => {
    const [weekDays, setWeekDays] = useState([])
    const oneRemToPX = remToPixels(1)
    const { selectedDate, userDoc, isLoggingActive, toggleTimeLogifyStatus } = useGlobals()
    const {
        logs,
        getLogs,
        refreshingLogs,
        handleDateChange,
    } = useLogs()
    const schedule = userDoc?.settings?.schedule || {}

    useEffect(() => {
        setInitialWeekDays()
    }, [])

    function setInitialWeekDays() {
        const firstDayOfWeek = new Date(selectedDate);
        const dayNo = selectedDate.getDay(); // (0 = Sunday, 6 = Saturday)
        const offset = (dayNo === 0 ? -6 : 1 - dayNo);
        firstDayOfWeek.setDate(selectedDate.getDate() + offset);
        const weekDays = []
        const startDate = new Date(firstDayOfWeek)
        for (let i = 0; i < 7; i++) {
            weekDays.push(new Date(startDate))
            startDate.setDate(startDate.getDate() + 1)
        }

        setWeekDays(weekDays)
    }

    useEffect(() => {
        if (!selectedDate || !weekDays.length) return

        const selectedDayStr = format(selectedDate, 'dd/MM/yyyy')
        const weekDaysStrArray = weekDays.map(day => format(day, 'dd/MM/yyyy'))

        if (!weekDaysStrArray.includes(selectedDayStr)) {
            setInitialWeekDays()
        }
    }, [selectedDate])

    function getHoursLoggedForDay(day) {
        const dayStr = format(day, 'dd/MM/yyyy')

        let totalMinutes = 0
        logs.forEach(log => {
            const logDateStr = format(convertTimestampToDate(log.startTime), 'dd/MM/yyyy')
            if (logDateStr === dayStr && log.title !== customInactiveTxt) {
                totalMinutes += log.logs.length
            }
        })
        return totalMinutes / 60
    }

    return (
        <div className='weekSelector' key='weekSelector'>
            {/* Date Selector */}
            <div className='weekSelectorHeader' key='weekSelectorHeader'>
                <div className='dateSelector'>
                    <div key='lastWeek' className='dateSelectorBtn' title='Previous week' onClick={() => handleDateChange(-7)}>&#8203;<RightArrowsIcon size={oneRemToPX} rotation={180} /></div>
                    <div key='yesterday' className='dateSelectorBtn' title='Previous day' onClick={() => handleDateChange(-1)}>&#8203;<RightArrowIcon size={oneRemToPX} rotation={180} /></div>
                    <div key='today' className='dateSelectorToday' title='Today' onClick={() => handleDateChange(0)}>Today</div>
                    <div key='tomorrow' className='dateSelectorBtn' title='Next day' onClick={() => handleDateChange(1)}>&#8203;<RightArrowIcon size={oneRemToPX} /></div>
                    <div key='nextWeek' className='dateSelectorBtn' title='Next week' onClick={() => handleDateChange(7)}>&#8203;<RightArrowsIcon size={oneRemToPX} /></div>
                </div>

                <ModularButton
                    title='Refresh'
                    iconEnd={<RefreshIcon size={oneRemToPX} customClass={refreshingLogs ? 'spinningRefreshIcon' : ''} />}
                    onClick={getLogs}
                    border={true}
                    noGap={true}
                    style={{ padding: '0.25rem 0.5rem', marginRight: '1rem', fontSize: '1rem' }}
                />

                <ModularButton
                    title={isLoggingActive ? 'Stop' : 'Start'}
                    tooltip={isLoggingActive ? 'Stop logging' : 'Start logging'}
                    iconEnd={isLoggingActive ?
                        <PauseIcon size={oneRemToPX} /> :
                        <PlayIcon size={oneRemToPX} />
                    }
                    onClick={toggleTimeLogifyStatus}
                    border={true}
                    noGap={true}
                    style={{ padding: '0.25rem 0.5rem', marginRight: '1rem', fontSize: '1rem' }}
                />
            </div>

            <VerticalSpacer />

            {/* Week days */}
            <div className='weekWrapper' key='weekWrapper'>
                {weekDays.map((day, index) => {
                    let extraDayClass = ''
                    let extraDayBarClass = ''
                    let extraDayBarFilledClass = ''

                    const fullDayStr = format(day, 'dd/MM/yyyy')
                    const dayStr = format(day, 'dd/MM')
                    const selectedDayStr = format(selectedDate, 'dd/MM')

                    if (dayStr === selectedDayStr) {
                        extraDayBarClass = 'dayBarSelected'
                        extraDayClass = 'daySelected'
                        extraDayBarFilledClass = 'dayBarFilledSelected'
                    }

                    const hoursLogged = getHoursLoggedForDay(day)

                    const weekDayStr = format(day, 'EEEE') // Don't ask why but 'EEEE' => Monday, Tuesday, ...
                    let scheduleForDay = schedule[weekDayStr.toLowerCase()]
                    let makeNarrow = false

                    if (scheduleForDay === 0) {
                        makeNarrow = true
                    }

                    if (scheduleForDay === 0 && hoursLogged === 0) {
                        scheduleForDay = 1
                    }

                    return (
                        <div
                            key={index}
                            className={`dayWrapper growOnHover ${makeNarrow ? ' dayWrapperNarrow' : ''}`}
                            onClick={() => handleDateChange(fullDayStr)}
                        >
                            <div className='dayBarWrapper' key={index}>
                                <div
                                    key={index}
                                    className={`dayBarFilled ${extraDayBarFilledClass}`}
                                    style={{
                                        height: `${Math.min(100, hoursLogged / scheduleForDay * 100)}%`,
                                        filter: scheduleForDay === 0 ? 'opacity(0.2)' : 'none',
                                    }}
                                >
                                </div>
                                <div className={`dayBar ${extraDayBarClass}`} />
                            </div>
                            <div className={`day ${extraDayClass}`} >
                                {dayStr}
                            </div>
                            {hoursLogged > 0 &&
                                <div className='hoverPopup hoverPopupForWeekSelector'>
                                    {formatTimeSpent(hoursLogged * 60 * 60)}
                                </div>
                            }
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default WeekSelector