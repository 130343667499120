import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as firestoreAPI from './firestoreAPI'
import * as timeLogifyAPI from './timeLogifyAPI'
import { auth } from './firebase'
import { hexToRgbForHTML } from './constants'

const GlobalsContext = createContext()

export const useGlobals = () => {
    return useContext(GlobalsContext)
}

export const GlobalsProvider = ({ children }) => {
    const [user, loading] = useAuthState(auth)
    const [userDoc, setUserDoc] = useState(null)
    const [selectedHostId, setSelectedHostId] = useState('')
    const [selectedLines, setSelectedLines] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [computer, setComputer] = useState('')
    const [userComputers, setUserComputers] = useState([])
    const [isLoggingActive, setIsLoggingActive] = useState(false)
    const [expanded, setExpanded] = useState(true)

    useEffect(() => {
        if (loading) return
        if (!user) {
            return
        }

        getUserDoc()
    }, [loading, user])

    async function getUserDoc() {
        const userDoc = await firestoreAPI.getUserDocument(user.uid)
        console.log('User:', userDoc.id)

        document.documentElement.style.setProperty('--accent-color', userDoc.settings.general.accentColor)
        document.documentElement.style.setProperty('--accent-color-rgb', hexToRgbForHTML(userDoc.settings.general.accentColor))

        setUserDoc(userDoc)
        return userDoc
    }

    const checkTimeLogifyStatus = useCallback(async () => {
        const isLoggingActive = await timeLogifyAPI.isLoggingActive()
        console.log('Logging active:', isLoggingActive)

        setIsLoggingActive(isLoggingActive)
        if (isLoggingActive) {
            toggleTimeLogifyStatus()
        }
    }, [])

    const toggleTimeLogifyStatus = useCallback(async () => {
        if (isLoggingActive) {
            await timeLogifyAPI.pauseLogging()
            setIsLoggingActive(false)
        } else {
            await timeLogifyAPI.startLogging()
            setIsLoggingActive(true)
        }
    }, [isLoggingActive])

    const exports = {
        userDoc, setUserDoc, getUserDoc, user, loading,
        selectedHostId, setSelectedHostId,
        selectedLines, setSelectedLines,
        selectedDate, setSelectedDate,
        computer, setComputer,
        userComputers, setUserComputers,
        isLoggingActive, setIsLoggingActive, toggleTimeLogifyStatus, checkTimeLogifyStatus,
        expanded, setExpanded,
    }

    return (
        <GlobalsContext.Provider value={exports}>
            {children}
        </GlobalsContext.Provider>
    )
}
