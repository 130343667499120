// Imports
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

// Components
import Sidebar from '../components/Sidebar'
import HeaderBar from '../components/HeaderBar'
import LoadingText from '../components/LoadingText'
import WeekSelector from '../components/WeekSelector'
import LogsContainer from '../components/LogsContainer'
import VerticalSpacer from '../components/VerticalSpacer'
import CircularProgress from '../components/CircularProgress'

// Functions
import * as timeLogifyAPI from '../mgt/timeLogifyAPI'
import * as firestoreAPI from '../mgt/firestoreAPI'
import { APP_VERSION } from '../mgt/constants'
import { useLogs } from '../mgt/LogsProvider'
import { useGlobals } from '../mgt/GlobalsProvider'
import { ExpandArrowsIcon, ShrinkArrowsIcon } from '../components/Icons'
import ModularButton from '../components/ModularButton'
import { getDashboardMinimized, setDashboardMinimized } from '../mgt/cachingMgt'

// Dashboard
function Dashboard() {
    const navigate = useNavigate()
    const { logsRetrieved } = useLogs()
    const {
        user,
        userDoc,
        setSelectedHostId,
        loading,
        computer, setComputer,
        userComputers, setUserComputers,
        checkTimeLogifyStatus,
        expanded, setExpanded,
    } = useGlobals()

    useEffect(() => {
        if (getDashboardMinimized()) {
            setExpanded(false)
        }
    }, [])

    // Initial Functions
    useEffect(() => {
        if (loading) return
        if (!user) { navigate('/login'); return }

        if (userDoc) {
            initialize()
        }
    }, [loading, user, userDoc])

    async function initialize() {
        console.log('User settings:', userDoc.settings)

        const userComputers = await getUserComputers()
        const isInstalled = await checkInstallation()

        if (!isInstalled) {
            return
        }

        await checkTimeLogifyStatus()

        userComputers &&
            await selectComputer(userComputers)
    }

    async function getUserComputers() {
        const userComputers = await firestoreAPI.getUserComputerDocuments(user.uid)
        console.log('computers:', userComputers.map(c => c.hostId))

        if (userComputers.length === 0) {
            navigate('/setup')
            return
        }
        setUserComputers(userComputers)
        return userComputers
    }

    async function checkInstallation() {
        const timeLogifyVersion = await timeLogifyAPI.getVersion()
        console.log('Application version:', timeLogifyVersion)
        console.log('Web version:', APP_VERSION)

        if (!timeLogifyVersion) {
            console.log('Application not installed or running.')
            navigate('/no-connection')
            return false
        } else {
            if (timeLogifyVersion !== APP_VERSION) {
                navigate('/update?v=' + APP_VERSION)
                return false
            }
            return true
        }
    }

    async function selectComputer(userComputers) {
        // Set this computer as selected
        const hostId = await timeLogifyAPI.getHostId()
        console.log('Host ID:', hostId)
        setSelectedHostId(hostId)
        const existingComputer = userComputers.find(computer => computer.hostId === hostId)
        if (existingComputer) {
            setComputer(existingComputer)  // TODO: fix logic when uninstalled but has logs
            return
        }
    }

    if (loading || !computer || !user || !logsRetrieved || !userDoc?.id)
        return <>
            <HeaderBar />
            <LoadingText />
        </>

    return <>
        <HeaderBar noMargin={true} />
        <div className='dashboardSection' style={{ width: '100%' }}>
            <Sidebar content={'computers'} userComputers={userComputers} computer={computer} user={user} />
            <div className='dashboardMain' style={{ width: expanded ? '100%' : '60%' }}>
                <VerticalSpacer />
                <div className='dashboardControls'>
                    <CircularProgress size={15} />
                    <WeekSelector />
                    <ModularButton
                        iconEnd={expanded ?
                            <ShrinkArrowsIcon />
                            :
                            <ExpandArrowsIcon />
                        }
                        onClick={() => {
                            setDashboardMinimized(expanded)
                            setExpanded(!expanded)
                        }}
                        narrow={true}
                        className='dashboardExpandBtn'
                    />
                </div>
                <VerticalSpacer size={2} />

                <LogsContainer />
            </div>
        </div>
    </>
}

export default Dashboard
