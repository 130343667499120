// Imports
import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import '../styles/App.css'

// Pages
import Home from './Home.jsx'
import Login from './Login.jsx'
import Register from './Register.jsx'
import SetupWizard from './SetupWizard.jsx'
import UpdateWizard from './UpdateWizard.jsx'
import Settings from './Settings.jsx'
import NotFound from './NotFound.jsx'
import UnderConstruction from './UnderConstruction.jsx'
import NoConnection from './NoConnection.jsx'
import Dashboard from './Dashboard.jsx'

// Providers
import { LogsProvider } from '../mgt/LogsProvider.js'
import { GlobalsProvider } from '../mgt/GlobalsProvider.js'
import { QueueProvider } from '../mgt/QueueProvider.js'

function App() {
  return (
    <GlobalsProvider>
      <LogsProvider>
        <QueueProvider>
          <div className="App">
            <Router>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/login' element={<Login />} />
                <Route path='/register' element={<Register />} />
                <Route path='/setup' element={<SetupWizard />} />
                <Route path='/update' element={<UpdateWizard />} />
                <Route path='/u/:userId' element={<Dashboard />} />
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/settings' element={<Settings />} />
                <Route path='/no-connection' element={<NoConnection />} />
                <Route path='*' element={<NotFound />} />
                <Route path='/under-construction' element={<UnderConstruction />} />
              </Routes>
            </Router>
          </div>
        </QueueProvider>
      </LogsProvider>
    </GlobalsProvider>
  )
}

export default App
