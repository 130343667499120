import React, { useEffect, useState } from 'react'
import { useGlobals } from '../mgt/GlobalsProvider'
import { tagBackgroundColor, tagBorderColor, tagColor } from '../mgt/constants';
import { CircleIcon, CopiedIcon, CopyIcon } from './Icons';
import { remToPixels } from '../mgt/layoutMgt';
import ModularButton from './ModularButton';
import { formatTimeSpent } from '../mgt/jiraAPI';
import { convertTimestampToDate } from '../mgt/timeMgt';
import { useLogs } from '../mgt/LogsProvider';

function areTagsEqual(tags1, tags2) {
    tags1 = tags1 || [];
    tags2 = tags2 || [];
    if (tags1.length !== tags2.length) return false;

    if ((tags1.length === 0) && (tags2.length === 0)) return true;
    if ((tags1.length === 0) || (tags2.length === 0)) return false;

    const sortedTags1 = [...tags1].sort((a, b) => a.category.localeCompare(b.category) || a.value.localeCompare(b.value));
    const sortedTags2 = [...tags2].sort((a, b) => a.category.localeCompare(b.category) || a.value.localeCompare(b.value));

    return sortedTags1.every((tag, index) =>
        tag.category === sortedTags2[index].category && tag.value === sortedTags2[index].value
    );
}

const oneRemToPixels = remToPixels(1)

const SummarizedLogsTable = () => {
    const { filteredLogs: logs } = useLogs()
    const [summary, setSummary] = useState([])
    const [copiedKeys, setCopiedKeys] = useState([])
    const [hoveredTag, setHoveredTag] = useState('')
    const [userTags, setUserTags] = useState([])
    const { userDoc } = useGlobals()

    useEffect(() => {
        if (!userDoc) return

        setUserTags([...userDoc.settings.tags])
    }, [userDoc])

    useEffect(() => {
        if (!logs?.length) {
            setSummary([])
            return
        }

        const min15 = 15 * 60

        const resultLogs = []
        const currLogs = [...logs]

        currLogs.sort((a, b) => a.startTime.seconds - b.startTime.seconds)
        currLogs.forEach((logDoc, _) => {
            if (resultLogs.length === 0) {
                resultLogs.push({ ...logDoc, duration: min15 })
                return
            }

            const prevLog = resultLogs[resultLogs.length - 1]
            if (logDoc?.tags?.length === 0 && prevLog?.tags?.length === 0) {
                prevLog.duration += min15
            } else if (areTagsEqual(logDoc.tags, prevLog.tags)) {
                prevLog.duration += min15
            } else {
                resultLogs.push({ ...logDoc, duration: min15 })
            }
        })
        resultLogs.sort((a, b) => b.startTime.seconds - a.startTime.seconds)

        console.log(resultLogs)

        setSummary(resultLogs)
    }, [logs])

    async function copyTagToClipboard(tag, rowIndex, tagIndex) {
        const tagKey = `${rowIndex}-${tagIndex}`
        if (copiedKeys.includes(tagKey)) return

        navigator.clipboard.writeText(tag.value)
        setCopiedKeys((prev) => [...prev, tagKey])

        setTimeout(() => {
            setCopiedKeys((prev) => prev.filter((key) => key !== tagKey))
        }, 1000)
    }

    return <>
        <div className='summarizedLogsContainer'>
            <div className='summarizedLogsHeader'>
                <div className='summarizedLogsHeaderTitle'>Start time</div>
                <div className='summarizedLogsHeaderTitle'>Duration</div>
                <div className='summarizedLogsHeaderTitle'>Tags</div>
            </div>
            <div className='summarizedLogsBody'>
                {summary.map((log, rowIndex) => {
                    const dateObj = convertTimestampToDate(log.startTime)
                    let time = dateObj.toLocaleTimeString('nl-BE')
                    time = time.slice(0, time.lastIndexOf(':'))

                    console.log(JSON.stringify(Object.keys(log).sort()))

                    const logTags = [...(log.tags || [])]

                // if (logTags.length === 0) {
                //     logTags.push({ category: '', value: 'No tags' })
                // }

                    return <div key={rowIndex} className='summarizedLogsBodyRow'>
                        <div className='summarizedLogsBodyTitle'>{time}</div>
                        <div className='summarizedLogsBodyTitle'>{formatTimeSpent(log.duration)}</div>
                        <div className='summarizedLogsBodyTitle'
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '0.25rem',
                            }}
                        >
                            {logTags.length === 0 ?
                                <div style={{ color: 'var(--text-color-subtlerer)' }}>No tags</div>
                                :
                                logTags
                                    ?.sort((a, b) => {
                                        if ((a.category === '') && (b.category !== '')) return 1
                                        if ((a.category !== '') && (b.category === '')) return -1

                                        const aIndex = userTags.findIndex(t => t.category === a.category)
                                        const bIndex = userTags.findIndex(t => t.category === b.category)
                                        return aIndex - bIndex
                                    })
                                    ?.map((tag, tagIndex) => {
                                        const color = userTags.find(t => t.category === tag.category)?.color
                                        const backgroundColor = color ? tagBackgroundColor(color) : 'rgba(var(--accent-color-rgb), 0.2)'
                                        const borderColor = color ? `${tagBorderColor(color)}` : 'rgba(var(--accent-color-rgb), 0.2)'
                                        const textColor = color ? tagColor(color) : 'rgba(var(--accent-color-rgb), 0.7)'
                                        const tagKey = `${rowIndex}-${tagIndex}`
                                        let isCopied = copiedKeys.includes(tagKey)

                                        return <div
                                            key={tagIndex}
                                            className='growOnHover'
                                            onMouseEnter={() => setHoveredTag(tagKey)}
                                            onMouseLeave={() => setHoveredTag('')}
                                        >
                                            <div className={rowIndex === 0 ? 'hoverPopdown' : 'hoverPopup'}>{isCopied ? 'Copied!' : 'Copy'}</div>
                                            <ModularButton
                                                key={tagIndex}
                                                title={tag.value}
                                                style={{
                                                    padding: '0.2rem 0.3rem 0.3rem 0.3rem',
                                                    fontSize: '0.8rem',
                                                    gap: '0.5rem',
                                                    borderRadius: '1rem 0.5rem 0.5rem 1rem',
                                                    backgroundColor: backgroundColor,
                                                    color: textColor,
                                                    border: `1px solid ${borderColor}`,
                                                }}
                                                onClick={() => copyTagToClipboard(tag, rowIndex, tagIndex)}
                                                border={true}
                                                wide={true}
                                                iconStart={
                                                    hoveredTag !== tagKey ?
                                                        <CircleIcon size={oneRemToPixels} color={textColor} />
                                                        :
                                                        copiedKeys.includes(tagKey) ?
                                                            <CopiedIcon size={oneRemToPixels} color={textColor} />
                                                            :
                                                            <CopyIcon size={oneRemToPixels} color={textColor} />}
                                            />
                                        </div>
                                    })
                            }
                        </div>
                    </div>
                })}
            </div>
        </div>
    </>
}

export default SummarizedLogsTable
