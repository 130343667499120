import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import { updateLogs } from './firestoreAPI'
import { useGlobals } from './GlobalsProvider'

const QueueContext = createContext()

/**
* @example
* const { addOperation, addNotification } = useQueue();
*/
export const useQueue = () => {
    return useContext(QueueContext)
}

export const QueueProvider = ({ children }) => {
    const queueRef = useRef([])
    const [processingQueue, setProcessingQueue] = useState(false)
    // const [notifications, setNotifications] = useState([])
    const { selectedHostId, userDoc } = useGlobals()

    function addOperation(operation) {
        console.log('Action queued:', operation)
        queueRef.current = [...queueRef.current, operation]
        setProcessingQueue(true)
    }

    // function addNotification(notification) {
    //     console.log('addNotification()', notification)
    //     setNotifications((prev) => [...prev, notification])
    // }

    async function processQueue() {
        if (queueRef.current.length === 0) return

        const currQueue = [...queueRef.current]
        queueRef.current = []

        await updateLogs(userDoc.id, selectedHostId, currQueue)
        setProcessingQueue(false)
    }

    useEffect(() => {
        if (!userDoc || !selectedHostId) return

        let interval = null
        let progressInterval = null

        // Process queue at 2s intervals
        interval = setInterval(() => {
            processQueue()
        }, 2000)

        return () => {
            clearInterval(interval)
            clearInterval(progressInterval)
        }
    }, [userDoc?.id, selectedHostId])

    return (
        <QueueContext.Provider value={{ addOperation }}>
            <div
                className='loadingSpinner'
                style={{
                    display: processingQueue ? 'block' : 'none',
                    position: 'absolute',
                    top: '4.2rem',
                    right: '0.2rem',
                    zIndex: 1000,
                    width: '0.8rem',
                    height: '0.8rem',
                }}
            />
            {children}
        </QueueContext.Provider>
    )
}
